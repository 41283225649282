@import "@radix-ui/colors/gray.css";
@import "@radix-ui/colors/iris.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/mint.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/sky.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/sage.css";
@import "@radix-ui/colors/amber.css";
@import "@radix-ui/colors/lime.css";
@import "@radix-ui/colors/orange.css";
@import "@radix-ui/colors/brown.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/gold.css";
@import "@radix-ui/colors/bronze.css";
@import "@radix-ui/colors/grass.css";
@import "@radix-ui/colors/jade.css";
@import "@radix-ui/colors/teal.css";
@import "@radix-ui/colors/indigo.css";
@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/plum.css";
@import "@radix-ui/colors/pink.css";
@import "@radix-ui/colors/crimson.css";
@import "@radix-ui/colors/ruby.css";
@import "@radix-ui/colors/tomato.css";
@import "@radix-ui/colors/sand.css";
@import "@radix-ui/colors/yellow.css";
@import "@radix-ui/colors/cyan.css";

.thread,
.composer {
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 8px 26px
        rgb(0 0 0 / 6%);
}

@keyframes scale-down {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

.scale-on-click {
    animation: scale-down 0.2s ease-in-out;
}

.scrollbar-white::-webkit-scrollbar {
    width: 12px; /* width of the scrollbar */
}

.scrollbar-white::-webkit-scrollbar-track {
    background: #ffffff; /* color of the scrollbar track */
}

.scrollbar-white::-webkit-scrollbar-thumb {
    background: #ffffff; /* color of the scrollbar thumb */
    border-radius: 6px; /* optional: round the edges */
}

.scrollbar-white::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1; /* optional: color on hover */
}

/* For Firefox */
.scrollbar-white {
    scrollbar-width: thin; /* options: auto, thin */
    scrollbar-color: #ffffff #ffffff; /* thumb color, track color */
}
