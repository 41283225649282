/* Base style for Dialog */
.cardCSS {
    margin-right: 125px;
    margin-left: 125px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.textfield-error input {
    border: 2px solid #e5484d; /* Makes the border red */
}

/* Small devices */
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cardCSS {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
}

/* Medium devices */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .cardCSS {
    }
}

/* Large devices */
@media only screen and (min-width: 901px) {
    .cardCSS {
        margin-right: "40%";
        margin-left: "40%";
        margin-bottom: "40%";
        margin-top: "40%";
        padding-right: "40%";
        padding-left: "40%";
    }
}
