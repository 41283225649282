.wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.editor {
    flex-grow: 1;
}

[data-lexical-editor] {
    padding: 2px 12px;
    outline: none;
}

[data-lexical-editor] p {
    margin: 0.8em 0;
}

.toolbar {
    padding: 12px;
    border-bottom: 1px solid #e6e6e7;
}

.threads {
    width: 350px;
    border-left: 1px solid #e6e6e7;
}

.thread {
    border-bottom: 1px solid #e6e6e7;
}

.thread .lb-thread-composer:after {
    content: none;
}

.placeholder {
    position: absolute;
    left: 12px;
    top: 16px;
    pointer-events: none;
    opacity: 0.5;
}

.floating-composer {
    width: 350px;
}

.add-comment {
    all: unset;
    font-weight: 500;
    padding: 12px;
    border-radius: 6px;
}

.add-comment:hover {
    background: #f4f4f5;
}

.emoji-menu {
    width: 200px;
}

.typeahead-popover {
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    position: fixed;
}

.typeahead-popover ul {
    padding: 0;
    list-style: none;
    margin: 0;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
    display: none;
}

.typeahead-popover ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.typeahead-popover ul li {
    margin: 0;
    min-width: 180px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
}

.typeahead-popover ul li.selected {
    background: #eee;
}

.typeahead-popover li {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
}

.typeahead-popover li.active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.typeahead-popover li:first-child {
    border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
    border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
    background-color: #eee;
}

.typeahead-popover li .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
}

.typeahead-popover li .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
